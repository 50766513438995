import React, { useEffect, useState } from "react";

import { ClientSidePrimeDataTable } from "../prime-data-table/tables/client-side-prime-data-table";
import { IPrimeMeta, IPrimeParams } from "../prime-data-table/interfaces/table-interfaces";
import { generateUrlParams } from "../prime-data-table/helpers/generateUrlParams";
import { PrimeDataTable } from "../prime-data-table/tables/prime-data-table";
import { mapToIds } from "../prime-data-table/helpers/primeHelpers";
import { sweetConfirm } from "../sweet-alert/sweetConfirm";
import CustomButton from "../custom-button/custom-button";
import { IDualTableProps, IIcons } from "./interfaces";

import "./style.scss";

export const DualTable = ({
    // Container props
    children,
    dualListName,
    handleSelected,
    className = "",
    layout = "row",
    selectChoices = undefined,
    permCode = undefined,
    handleSaveSelected = undefined,
    selectAllDisabled = false,
    refreshButtonDisabled = false,
    higherDependencyId = undefined,
    buttonsUnselectDisabled = false,
    additionalButtons = undefined,
    singleSelect = false,
    setHighlights,
    highlights,
    // Clearing props
    // First table props
    dataSet = undefined,
    meta = undefined,
    columns,
    handleReload,
    // Second  table props
    secondTableDataSet = undefined,
    secondTableColumns = undefined,
    secondTableOnSelect = undefined,
    minimumColWidth = undefined,
    btnContainerClassName = "",
    // custom key
    dataKey = "id",
}: IDualTableProps) => {
    const [records, setRecords] = useState<any[]>();
    const [recordsToSave, setRecordsToSave] = useState<any[]>(secondTableDataSet || []);

    const [selectedRecords, setSelectedRecords] = useState<any[]>([]);
    const [selectedRecordsToSave, setSelectedRecordsToSave] = useState<any>([]);

    const [params, setParams] = useState<IPrimeParams>({ filters: undefined, multiSortMeta: undefined });

    const [secondTableMeta, setSecondTableMeta] = useState<IPrimeMeta | undefined>(
        meta
            ? {
                  first: meta.page < 2 ? 0 : (meta.page - 1) * meta.pageSize,
                  page: 1,
                  rows: meta.pageSize,
                  totalRecords: secondTableDataSet?.length || 0,
              }
            : undefined
    );
    const [isButtonBusy, setIsButtonBusy] = useState<boolean>(false);
    const [icons] = useState<IIcons>(
        layout == "row"
            ? {
                  refresh: "refresh-ccw",
                  selectSingle: "chevron-right",
                  selectAll: "chevrons-right",
                  deleteAll: "chevrons-left",
                  deleteSingle: "chevron-left",
                  save: "save",
              }
            : {
                  refresh: "refresh-ccw",
                  selectSingle: "chevron-down",
                  selectAll: "chevrons-down",
                  deleteAll: "chevrons-up",
                  deleteSingle: "chevron-up",
                  save: "save",
              }
    );

    useEffect(() => {
        setRecords(dataSet);
    }, [dataSet]);

    // useEffect(() => {
    //     singleSelect && selectedRecords.length && setSelectedRecordsToSave([]);
    // }, [selectedRecords]);

    // useEffect(() => {
    //     singleSelect && selectedRecordsToSave.length && setSelectedRecords([]);
    // }, [selectedRecordsToSave]);

    useEffect(() => {
        setSecondTableMeta(
            meta
                ? {
                      first: meta.page < 2 ? 0 : (meta.page - 1) * meta.pageSize,
                      page: 1,
                      rows: meta.pageSize,
                      totalRecords: secondTableDataSet?.length || 0,
                  }
                : undefined
        );
    }, [meta]);

    useEffect(() => {
        setRecordsToSave(secondTableDataSet || []);
        handleSelected && handleSelected(secondTableDataSet || []);
    }, [JSON.stringify(secondTableDataSet)]);

    const resetDualTable = () => {
        handleReload();
        setSecondTableMeta(
            meta
                ? {
                      first: meta.page < 2 ? 0 : (meta.page - 1) * meta.pageSize,
                      page: 1,
                      rows: meta.pageSize,
                      totalRecords: secondTableDataSet?.length || 0,
                  }
                : undefined
        );
    };

    const handleReset = async () => {
        if (!(await sweetConfirm("Reset", "Do you want to reset your selection?"))) return;
        resetDualTable();
    };

    const handleSave = async () => {
        if (!(await sweetConfirm("Save", "Do you want to save your selection?"))) return;
        handleSaveSelected && handleSaveSelected();
    };

    const handleSelectedRecord = async (selected: any[] = []) => {
        if (selected.length < 1) return;
        setIsButtonBusy(true);

        const mod = meta ? meta?.count % meta?.pageSize : 0;
        let newRecordsToSave: any[] = recordsToSave.length > 0 ? [...recordsToSave, ...selected] : [...selected];
        let page = meta?.page;

        newRecordsToSave = newRecordsToSave.map((record) => ({ ...record, isSelected: false }));

        if (meta && meta?.lastPage == meta?.page && selected.length == (mod == 0 ? meta?.pageSize : mod)) {
            page = meta?.page > 1 ? meta?.page - 1 : 1;
        }

        await handleReload(
            generateUrlParams({ page: page, rows: meta?.pageSize, multiSortMeta: params.multiSortMeta, filters: params.filters }),
            newRecordsToSave.map((r) => r[dataKey])
        );

        setIsButtonBusy(false);
    };

    const handleSelectAll = async () => {
        setIsButtonBusy(true);

        await handleReload(
            generateUrlParams({ multiSortMeta: params.multiSortMeta, filters: params.filters }) + "&selectAll=true",
            recordsToSave.map((r) => r[dataKey])
        );

        setIsButtonBusy(false);
    };

    const handleDeletedRecord = async (selected: any[]) => {
        if (selected.length < 1) return;
        setIsButtonBusy(true);

        const selectedIds = selected.map((r) => r.id);

        await handleReload(
            generateUrlParams({ page: meta?.page, rows: meta?.pageSize, multiSortMeta: params.multiSortMeta, filters: params.filters }),
            recordsToSave.filter((r) => !selectedIds.includes(r.id)).map((r) => r.id)
        );

        setIsButtonBusy(false);
    };

    const handleSecondTableMetaChange = (meta) => {
        setSecondTableMeta(meta);

        if (recordsToSave.length > 0) {
            const paginatedDataSet = getPaginatedDataSet(meta);
            setSelectedRecordsToSave([paginatedDataSet[0]]);
        }
    };

    const getPaginatedDataSet = (pagination) => {
        const { rows, page } = pagination;
        const start = (page - 1) * rows;
        const end = recordsToSave.length < page * rows ? recordsToSave.length : page * rows;
        return recordsToSave.slice(start, end);
    };

    return (
        <div className={`${layout == "row" ? "dual-table-row-container" : "dual-table-container"} ${className}`}>
            <div className="first-table-container">
                <PrimeDataTable
                    refreshCallBackFunctions={[recordsToSave]}
                    higherDependencyId={higherDependencyId}
                    tableName={dualListName ? `${dualListName}_first-table` : ""}
                    sortable
                    dataSet={records}
                    onReload={(params) => handleReload(params, mapToIds(recordsToSave))}
                    columns={columns}
                    meta={meta}
                    selectedRecords={highlights?.length ? highlights : selectedRecords}
                    onSelect={(selected) => {
                        setHighlights && setHighlights(selected);
                        return setSelectedRecords(selected);
                    }}
                    handleDoubleClick={(e) => handleSelectedRecord([e.data])}
                    selectChoices={selectChoices}
                    minimumColWidth={minimumColWidth}
                    btnContainerClassName={btnContainerClassName}
                    onParamsChange={setParams}
                    // notSelectFirst={singleSelect}
                    disableAdditionalColumns
                    selectionMode={singleSelect ? "single" : "multiple"}
                >
                    {children}
                </PrimeDataTable>
            </div>
            <div className="button-container">
                {handleSaveSelected && (
                    <CustomButton cyId={`${dualListName}-handle-save`} permCode={permCode} icon={icons.save} onClick={handleSave} />
                )}
                <CustomButton
                    cyId={`${dualListName}-select-all`}
                    permCode={permCode}
                    icon={icons.selectAll}
                    withoutPerm={!permCode}
                    onClick={() => handleSelectAll()}
                    disabled={!records || records?.length < 1 || isButtonBusy || selectAllDisabled}
                />
                <CustomButton
                    cyId={`${dualListName}-select-one`}
                    permCode={permCode}
                    icon={icons.selectSingle}
                    withoutPerm={!permCode}
                    onClick={() => handleSelectedRecord(selectedRecords)}
                    disabled={
                        !records ||
                        records?.length < 1 ||
                        selectedRecords.length < 1 ||
                        isButtonBusy ||
                        (singleSelect && highlights?.[0]?.id != selectedRecords[0]?.id)
                    }
                />
                <CustomButton
                    cyId={`${dualListName}-delete-one`}
                    permCode={permCode}
                    icon={icons.deleteSingle}
                    color="danger"
                    onClick={() => handleDeletedRecord(selectedRecordsToSave)}
                    withoutPerm={!permCode}
                    disabled={
                        buttonsUnselectDisabled ||
                        (recordsToSave && recordsToSave.length < 1) ||
                        selectedRecordsToSave.length < 1 ||
                        isButtonBusy ||
                        (singleSelect && highlights?.[0]?.id != selectedRecordsToSave[0]?.id)
                    }
                />
                <CustomButton
                    cyId={`${dualListName}-delete-all`}
                    permCode={permCode}
                    icon={icons.deleteAll}
                    color="danger"
                    withoutPerm={!permCode}
                    onClick={() => handleDeletedRecord(recordsToSave)}
                    disabled={buttonsUnselectDisabled || (recordsToSave && recordsToSave.length < 1) || isButtonBusy || selectAllDisabled}
                />
                <CustomButton
                    cyId={`${dualListName}-reset`}
                    permCode={permCode}
                    icon={icons.refresh}
                    color="danger"
                    onClick={handleReset}
                    withoutPerm={!permCode}
                    disabled={buttonsUnselectDisabled || refreshButtonDisabled}
                />
                {additionalButtons}
            </div>
            <div className="second-table-container">
                <ClientSidePrimeDataTable
                    btnContainerClassName={layout == "row" ? "hpx-36" : ""}
                    columns={secondTableColumns ? secondTableColumns : columns}
                    tableName={dualListName ? `${dualListName}_second-table` : ""}
                    dataSet={recordsToSave}
                    clientSideMeta={secondTableMeta}
                    onClientSideMetaChange={handleSecondTableMetaChange}
                    onSelect={(selected) => {
                        setHighlights && setHighlights(selected);
                        if (selected[0]?.[dataKey] != selectedRecordsToSave[0]?.[dataKey] || selected.length > 1)
                            setSelectedRecordsToSave(selected);
                        if (secondTableOnSelect) secondTableOnSelect(selected);
                    }}
                    handleDoubleClick={(e) => !buttonsUnselectDisabled && handleDeletedRecord([e.data])}
                    selectedRecords={highlights?.length ? highlights : selectedRecordsToSave}
                    minimumColWidth={minimumColWidth}
                    hidingColumnEnabled
                    disableAdditionalColumns
                    selectionMode={singleSelect ? "single" : "multiple"}
                />
            </div>
        </div>
    );
};
