import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import classnames from "classnames/dedupe";

import CustomButton from "components/custom-button/custom-button";

export const OverflowMenu = ({ children, init }) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [isOutsideWindow, setIsOutsideWindow] = useState<number | undefined>(undefined);

    const menuRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            const modalEl = document.getElementsByClassName("modal fade");
            if (!modalEl.length) handleToggleMenu();
        };

        if (isMenuOpen) document.addEventListener("click", handleClickOutside);

        return () => document.removeEventListener("click", handleClickOutside);
    }, [isMenuOpen]);

    const handleToggleMenu = () => setIsMenuOpen((prev) => !prev);

    useLayoutEffect(() => {
        const rect = menuRef.current?.getBoundingClientRect();

        isMenuOpen &&
            setIsOutsideWindow(
                !!rect && innerRef?.current && rect?.right + rect?.width > window.innerWidth
                    ? rect?.width - innerRef?.current.clientWidth
                    : undefined
            );
    }, [isMenuOpen]);

    return (
        <div className="overflow-menu-container-relative">
            <div ref={menuRef} className="overflow-menu-container">
                <CustomButton
                    className={classnames("overflow-buttons-menu-btn", "btn", "btn-outline-gt-success", "button-wrapper", {
                        open: isMenuOpen,
                        init: init,
                    })}
                    icon={"chevron-down"}
                    innerRef={innerRef}
                    onClick={handleToggleMenu}
                    text="More"
                    withoutPerm
                />
                {isMenuOpen && (
                    <div
                        className="overflow-buttons-menu-container"
                        style={isOutsideWindow ? { right: `${isOutsideWindow}px` } : undefined}
                    >
                        <div className="overflow-buttons-menu">{children}</div>
                    </div>
                )}
            </div>
        </div>
    );
};
