import React from "react";

import "./utils/prototype-functions";
import "./globals";
import "./methods";

import registerServiceWorker from "serviceWorkers/registerServiceWorker";
import { I18nextProvider } from "react-i18next";
import ReactDOM from "react-dom";
import axios from "axios";
import i18n from "./i18n";
import App from "App";

const getApiUrl = () => {
    //@ts-ignore
    if (window?.config?.API_URL) axios.defaults.baseURL = window.config.API_URL;
    else if (process.env.REACT_APP_USE_APACHE == "true") {
        axios.defaults.baseURL = `${process.env.REACT_APP_DOMAIN}/admin/api`;
    } else {
        axios.defaults.baseURL = `${window.location.protocol}//${window.location.hostname}:${
            window.location.hostname.includes("demo") ? process.env.REACT_APP_BACK_REMOTE_PORT : process.env.REACT_APP_BACK_PORT
        }/api`;
    }
};

getApiUrl();

axios.defaults.headers.common["accept-language"] = i18n.language;
axios.defaults.headers.common["lng"] = i18n.language;
axios.defaults.withCredentials = true;

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>,
    document.getElementById("app")
);

registerServiceWorker("sw");
