import React, { useEffect, useRef } from "react";
import { useDebouncedCallback } from "./useDebouncedCallback";

export const useResizeEnd = () => {
    const resizeTriggerRef = useRef<boolean>(false);

    const resizeEnd = () => {
        resizeTriggerRef.current = !resizeTriggerRef.current;
    };

    const debouncedResizeEnd = useDebouncedCallback(resizeEnd, 300);

    useEffect(() => {
        window.addEventListener("resize", debouncedResizeEnd);

        return () => window.removeEventListener("resize", debouncedResizeEnd);
    }, []);

    return resizeTriggerRef.current;
};
