import React from "react";

import "../../page-layout.scss";

const PageContent = ({ children, className = "" }) => {
    return (
        <div id="page-container" className={`rui-page-content ${className} table-grid-layout`}>
            {children}
        </div>
    );
};

export default PageContent;
