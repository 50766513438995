import { useEffect, useRef, useState } from "react";

import { useResizeEnd } from "./useResizeEnd";

export const useTableScrollHeight = (gtTableRef, filtersEnabled, children, meta) => {
    const [scrollHeight, setScrollHeight] = useState<number>(0);

    const resizeEndTrigger = useResizeEnd();

    //? TABLE SCROLL
    useEffect(() => {
        !!scrollHeight && setScrollHeight((prev) => prev + (filtersEnabled ? -32 : 32));
    }, [filtersEnabled]);

    useEffect(() => {
        getScrollHeight(gtTableRef.current.clientHeight);
    }, [!!meta]);

    const getScrollHeight = (clientHeight) => {
        const HEADERS = 32;
        const BUTTONS = !!children ? 38 : 0;
        const TABLE_BUTTONS_GAP = 10;
        const PAGINATION_BAR = !!meta ? 41 : 0;
        const BUFFER = 5;
        const FILTERS = filtersEnabled ? 32 : 0;
        const MIN_TABLE_HEIGHT = 165;
        const fullscreenContainers = document.getElementsByClassName("fullscreen");

        if (!!fullscreenContainers.length) return MIN_TABLE_HEIGHT;

        let calculatedScrollHeight: number = clientHeight - HEADERS - BUTTONS - TABLE_BUTTONS_GAP - PAGINATION_BAR - BUFFER - FILTERS;

        if (calculatedScrollHeight < MIN_TABLE_HEIGHT) calculatedScrollHeight = MIN_TABLE_HEIGHT;
        setScrollHeight(calculatedScrollHeight);
    };

    return { scrollHeight };
};
