import React from "react";

import { AxiosPromise, AxiosResponse } from "axios";

import { sweetConfirm } from "components/sweet-alert/sweetConfirm";

interface IGTRequest {
    request: () => AxiosPromise;
    onSuccess?: ((response: AxiosResponse) => void) | Function;
    onError?: (e: any) => void;
    setErrors?: React.Dispatch<React.SetStateAction<IErrors>>;
    sweetConfirmConfig?: {
        title: string;
        content: string;
        count?: string | number;
    };
}

export const handleRequest = async ({ request, sweetConfirmConfig, setErrors, onSuccess, onError }: IGTRequest) => {
    if (
        sweetConfirmConfig &&
        !(await sweetConfirm(sweetConfirmConfig.title, { text: sweetConfirmConfig.content, count: sweetConfirmConfig.count }))
    )
        return;

    try {
        const response = await request();

        onSuccess && (await onSuccess(response));

        return response.status;
    } catch (e: any) {
        console.error(e);
        onError && onError(e);
        if (setErrors) {
            e.response?.status == "400" && setErrors(e.response.data);
        }
        if (e.response?.status == "403") return e.response.data;
        else return e.response?.status;
    }
};
