import React, { memo, useState } from "react";

import { Col, Row } from "reactstrap";
import { snakeCase } from "lodash";
import axios from "axios";

import { IAdvancedRemark } from "components/prime-data-table/interfaces/edit-interfaces";
import { RichTextInput } from "../../../../rich-text-input.tsx/rich-text-input";
import { ModalAdvancedRemarksColumns } from "./advanced-remarks-columns";
import CustomButton from "../../../../custom-button/custom-button";
import { PrimeDataTable } from "../../../tables/prime-data-table";
import { useFetchTableData } from "hooks/useFetchTableData";

const AdvancedRemarksTable = ({ collapseData, modelName, rowId, typeOptions, setCollapseData }) => {
    const [selectedRemarks, setSelectedRemarks] = useState<IAdvancedRemark[]>([]);

    const { data, refreshData, handleReload } = useFetchTableData<IAdvancedRemark>({
        url: !collapseData?.isOpen ? `/advanced-remarks` : undefined,
        configParams: modelName ? { params: { [snakeCase(modelName)]: rowId } } : undefined,
        groupedOnInit: true,
        triggerValues: [!collapseData?.isOpen],
    });

    const handleWithdrawn = async () => {
        try {
            await axios.patch(`/advanced-remarks/${selectedRemarks[0].id}/withdrawn`);
            refreshData();
        } catch (err: any) {}
    };

    return (
        <>
            <div className="mb-10">
                <CustomButton
                    text="Add remark"
                    icon="plus"
                    disabled={collapseData.isOpen}
                    onClick={() => setCollapseData((prev) => ({ isOpen: !prev.isOpen, selectedPosition: "" }))}
                    permCode="add.other.advanced-remarks.add"
                />
                <CustomButton
                    text="Withdraw"
                    disabled={collapseData.isOpen || selectedRemarks.length != 1 || selectedRemarks[0]?.isWithdrawn}
                    onClick={handleWithdrawn}
                    color="danger"
                    permCode="change.other.advanced-remarks.withdraw"
                />
            </div>
            <Row>
                <Col sm={7}>
                    <PrimeDataTable
                        tableName="advanced-remarks"
                        dataSet={data?.data}
                        meta={data?.meta}
                        columns={ModalAdvancedRemarksColumns}
                        selectedRecords={selectedRemarks}
                        onSelect={setSelectedRemarks}
                        onReload={handleReload}
                        cellEditEnabled
                        selectChoices={typeOptions}
                        firstPartEditUrl="/advanced-remarks"
                        permCode="change.other.advanced-remarks.change"
                        useFetchTableData
                    />
                </Col>
                <Col sm={5}>
                    <RichTextInput value={selectedRemarks[0]?.content} readOnly style={{ height: "420px" }} />
                </Col>
            </Row>
        </>
    );
};

export const MemoAdvancedRemarkTable = memo(AdvancedRemarksTable);
