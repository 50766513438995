import React from "react";

import { RadioButton as PrimeRadioButton, RadioButtonChangeParams } from "primereact/radiobutton";
import { useTranslation } from "react-i18next";
import classnames from "classnames/dedupe";
import { Label } from "reactstrap";

interface RadioButton {
    name?: string;
    label: string;
    checked?: boolean;
    className?: string;
    justify?: "start" | "center" | "end";
    onChange: (e: RadioButtonChangeParams) => void;
}

export const RadioButton = (props: RadioButton) => {
    const { name, label, checked, justify = "center", className, onChange } = props;

    const { t } = useTranslation();

    const handleChange = (e) => {
        onChange(e);
    };

    return (
        <div className={classnames(`d-flex align-items-center justify-content-${justify} flex-row`, className)}>
            <PrimeRadioButton name={name} onChange={handleChange} checked={checked} />
            <Label className="mb-0 pl-5">{t(label)}</Label>
        </div>
    );
};

